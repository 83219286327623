import PrivacyPolicy from "components/policies/privacyPolicy"
import Metadata from "components/utils/metadata/metadata"
import { useFooter } from "hooks/useFooter"
import { useNavBar } from "hooks/useNavBar"
import React from "react"

const Privacy = ({ location }) => {
  // Don't use the navbar on this page
  useNavBar({
    hasNavBar: false,
  })

  // Use a footer
  useFooter(true)

  return (
    <>
      <Metadata title="Privacy" pathname={location.pathname} />

      <PrivacyPolicy />
    </>
  )
}

export default Privacy
Privacy.whyDidYouRender = true
